<template>
    <div class="main-container">
        
        <Header />

        <div class="breadcrumb-area">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="breadcrumb-wrap text-center">
                            <div class="section-title">
                                <span class="water-text">Détails du projet</span>
                                <h2 class="title">Galaxy Swiss Bourdin</h2>
                            </div>
                            <nav aria-label="breadcrumb">
                                <ul class="breadcrumb">
                                  <li class="breadcrumb-item">
                                    <router-link to="/">Accueil</router-link>
                                  </li>
                                  <li class="breadcrumb-item">
                                    <router-link to="/project">Portfolio</router-link>
                                  </li>
                                    <li class="breadcrumb-item active" aria-current="page">
                                        Galaxy Swiss Bourdin
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <GSBDetailsWrapper />

        <Footer />

        <OffCanvasMobileMenu />

        <BackToTop />

    </div>
</template>

<script>
    import Header from '@/components/Header';
    import GSBDetailsWrapper from '@/components/projects/GSBDetailsWrapper';
    import Footer from '@/components/Footer';
    import OffCanvasMobileMenu from '@/components/OffCanvasMobileMenu';
    import BackToTop from '@/components/BackToTop';

    export default {
        components: {
            Header,
            GSBDetailsWrapper,
            Footer,
            OffCanvasMobileMenu,
            BackToTop
        },
        metaInfo: {
            title: 'Projet GSB',
            htmlAttrs: {
                lang: 'fr',
                amp: true
            }
        }
    }
</script>
